<template>
 <Loading v-show="show" />
  <div class="breadcrumb-banner-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-text">
            <h1 class="text-left" v-if="$route.params.type=='Univ.Services'">{{ Service }}</h1>
                        <h1 class="text-left" v-else>{{ AboutUs }}</h1>

            <div class="breadcrumb-bar">
              <ul class="breadcrumb text-left">
                <li>
                  <router-link to="/">{{ home }}</router-link>
                </li>
                <li v-if="$route.params.type=='Univ.Services'">{{ Service }}</li>
                <li v-else>{{ AboutUs }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="latest-area section-padding" style="padding: 50px 0 60px">
    <div class="container">
      <div class="row">
        <div
          id="univsectionall"
          v-for="KnowUniv in KnowUniv"
          :key="KnowUniv.postId"
          class="col-lg-4 col-md-6"
        >
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="
                    'https://api2.yg-u.net/images/post/' + KnowUniv.postImage
                  "
                />
              </a>
            </div>
            <div class="single-event-text">
              <h6 style="margin-bottom: 10px">
                <a href="javascript:void(0)">{{ KnowUniv.postTitle }}</a>
              </h6>
              <p>{{ KnowUniv.postSubTitle }}</p>
   <div v-if="$route.params.type=='Univ.Services'">
              <router-link
                class="button-default"
                :to="{
                  name: 'Service',
                  params: {
                    id: KnowUniv.postID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
              </div>

                <div v-else>
              <router-link
                class="button-default"
                :to="{
                  name: 'Univ-with-id-And-Type',
                  params: {
                    type: 'Univ.Home.Sec.KnowUniv',
                    id: KnowUniv.postID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from './Loading'

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
   components:{
Loading
  },
  data() {
    return {
      KnowUniv: [],
      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      AboutUs: "",
      show:true,
      Detials: "",
      Service: ""
    };
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function (response) {
          console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();

        self.translate = self.ArTranslat;

        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.AboutUs = self.translate[0]["Home"]["AboutUs"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.Service = self.ArTranslat[0]["HeaderAndFooter"]["Service"];


        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);

        self.translate = self.EnTranslat;

        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.AboutUs = self.translate[0]["Home"]["AboutUs"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.getApis();
      }
    }
  },
  methods: {
    getApis() {
       var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      //bodyFormData.append("Type", "Univ.Home.Sec.KnowUniv");
    bodyFormData.append("Type", this.$route.params.type);  //this.$route.params.type
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          self.KnowUniv = response.data;
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);

            setTimeout(() => {
            self.show = false;
            document.getElementById('YG_U').setAttribute('style','display:none !important')

          }, 100);

        })
        .catch(function (response) {
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.KnowUniv_top {
  margin-top: 50px;
}
.single-event-image span {
  font-size: 28px !important;
}
.univsectionall {
  margin-top: 50px;
}
</style>
